<template>
  <div class="page">
    <div class="wrapper illustration illustration_brackets">
      <Backlink title="questionnaire" service="refCredit" backlink="/refinance-credit/problems" />
      <div class="content">
        <AboutProblems service="refCredit" nextlink="/refinance-credit/delay" />
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import AboutProblems from '@/components/service/AboutProblems.vue'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Refinance_credit_about_problems',
  components: { Backlink, AboutProblems }
}
</script>
